module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"rockymountainweb","schemas":{"home_page":{"Main":{"hero_title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Hero Title"}},"hero_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Hero Image"}},"button_text":{"type":"Text","config":{"label":"Button Text"}},"button_link":{"type":"Link","config":{"allowTargetBlank":true,"label":"Button Link"}}},"SEO":{"meta_title":{"type":"Text","config":{"label":"Meta Title"}},"meta_description":{"type":"Text","config":{"label":"Meta Description"}}}},"contact_us":{"Main":{"uid":{"type":"UID","config":{"label":"uid"}},"owner_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Owner Image"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#246EB9","theme_color":"#246EB9","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d179d2b27c341b612d45c1c629cee615"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"duration":1000},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144523198-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"enableWebVitalsTracking":true,"exclude":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
